import axios from "./index";

// document manager api requests
export const _getDocuments = () => {
  return axios.get(`/v1/admin/get/document/history`);
};

export const _getDocs = (refID) => {
  return axios.get(`/v1/admin/get/doc/history/${refID || "-"}/-`);
};

export const _searchDocuments = (obj) => {
  return axios.get(`/v1/admin/get/document/s/${obj}`);
};

export const _createDocument = (reqObj) => {
  return axios.post("/v1/admin/add/document", reqObj);
};

export const _updateDocument = (reqObj) => {
  return axios.post("/v1/admin/add/document", reqObj);
};

export const _uploadFileToDocument = (reqObj) => {
  return axios.put("/v1/admin/add/filetodocument", reqObj);
};

export const _deleteDocument = (reqObj) => {
  return axios.delete("/v1/admin/delete/document", { data: reqObj });
};

export const _deleteFile = (reqObj) => {
  return axios.delete("/v1/admin/delete/doc", { data: reqObj });
};
