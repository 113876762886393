import React from "react";
import { Modal, Spinner, Col, Row, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { createDocumentAction } from "../../store/actions/DocumentActions";

function AddCategory({ open, toggle, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleCreateCompany = async (values) => {
    console.log("values");
    setLoading(true);

    const {
      companyID,
      name,
      category,
      title,
      others,
      consultantname,
      companyname,
      date,
    } = values;

    const reqObj = {
      data: {
        companyID,
        name,
        category,
        details: `${
          title +
          ", " +
          consultantname +
          ", " +
          date +
          ", " +
          companyname +
          ", " +
          others
        }`,
      },
    };

    console.log("hello", reqObj);

    const response = await dispatch(createDocumentAction(reqObj));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Create New Document</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            companyID: "history",
            name: "",
            category: "",
            companyname: "",
            title: "",
            others: "",
            consultantname: "",
            date: "",
          }}
          validationSchema={yup.object({
            name: yup.string().required("name is required"),
            category: yup.string().required("category is required"),
            title: yup.string().required("Required"),
            others: yup.string(),
            consultantname: yup.string().required("Required"),
            companyname: yup.string().required("Required"),
            date: yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            console.log("login credentials ", values);
            handleCreateCompany(values);
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <>
              <Row sm="12">
                <Col md="6" className="form-group">
                  <label className="form-label" htmlFor="category">
                    Category
                  </label>
                  <input
                    id="category"
                    type="text"
                    onChange={handleChange("category")}
                    onBlur={handleBlur("category")}
                    value={values.category}
                    placeholder="Document Category"
                    style={{ fontSize: "1rem" }}
                    className="form-control"
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.category && errors.category}{" "}
                  </small>
                </Col>
                <Col md="6">
                  <label className="form-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    id="name"
                    type="name"
                    placeholder="Document Name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    className="form-control"
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.name && errors.name}{" "}
                  </small>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>MetaData Details</h6>
                </Col>
              </Row>
              <Row sm="12">
                <Col md={6}>
                  <label className="my-2 form-label" htmlFor="title">
                    Title
                  </label>
                  <input
                    id="title"
                    type="text"
                    placeholder="Title"
                    onChange={handleChange("title")}
                    onBlur={handleBlur("title")}
                    value={values.title}
                    className="form-control "
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.title && errors.title}{" "}
                  </small>
                </Col>

                <Col md={6}>
                  <label className="my-2 form-label" htmlFor="companyname">
                    Company Name
                  </label>
                  <input
                    id="companyname"
                    type="text"
                    placeholder="Company Name"
                    onChange={handleChange("companyname")}
                    onBlur={handleBlur("companyname")}
                    value={values.companyname}
                    className="form-control "
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.companyname && errors.companyname}{" "}
                  </small>
                </Col>

                <Col md={6}>
                  <label className="my-2 form-label" htmlFor="consultantname">
                    Consultant Name
                  </label>
                  <input
                    id="consultantname"
                    type="text"
                    placeholder="Consultant Name"
                    onChange={handleChange("consultantname")}
                    onBlur={handleBlur("consultantname")}
                    value={values.consultantname}
                    className="form-control "
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.consultantname && errors.consultantname}{" "}
                  </small>
                </Col>

                <Col md={6}>
                  <label className=" my-2 form-label" htmlFor="date">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    placeholder="Date"
                    className="my-2"
                    onChange={handleChange("date")}
                    onBlur={handleBlur("date")}
                    value={values.date}
                    className="form-control"
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.date && errors.date}{" "}
                  </small>
                </Col>

                <Col md={12}>
                  <label className="my-2 form-label" htmlFor="others">
                    Other tag details
                  </label>
                  <textarea
                    id="others"
                    type="text"
                    placeholder="Eg: Report, Finance, Strategy etc"
                    onChange={handleChange("others")}
                    onBlur={handleBlur("others")}
                    value={values.others}
                    className="form-control "
                  />
                  <small className="text-danger">
                    {" "}
                    {touched.others && errors.others}{" "}
                  </small>
                </Col>
              </Row>

              <Row>
                <Col className="my-4 text-right">
                  <Button
                    disabled={loading}
                    type="submit"
                    onClick={handleSubmit}
                    size="sm"
                  >
                    {loading && (
                      <Spinner
                        animation="border"
                        variant="secondary"
                        size="sm"
                      />
                    )}
                    &nbsp; Create Document
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AddCategory;
