import { GET_DOCUMENTS, GET_DOCS } from "../types";

const initialState = {
  documents: [],
  docs: [],
};

export default function (state = initialState, actions) {
  switch (actions.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: actions.payload,
      };
    case GET_DOCS:
      return {
        ...state,
        docs: actions.payload,
      };
    default: {
      return state;
    }
  }
}
