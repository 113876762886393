import {
  _getDocuments,
  _getDocs,
  _createDocument,
  _updateDocument,
  _deleteDocument,
  _uploadFileToDocument,
  _searchDocuments,
} from "../../api/DocumentsRequest";
import { NotificationManager } from "react-notifications";
import { GET_DOCUMENTS, GET_DOCS } from "../types";

export const getDocuments = () => {
  return async (dispatch) => {
    try {
      const { data } = await _getDocuments();
      console.log("documents login ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_DOCUMENTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const searchDocuments = (obj) => {
  return async (dispatch) => {
    try {
      const { data } = await _searchDocuments(obj);
      console.log("documents login ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_DOCUMENTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getDocs = (refID) => {
  return async (dispatch) => {
    try {
      const { data } = await _getDocs(refID);
      console.log("docs resp ", data);
      if (data.error.status == 1) {
        NotificationManager.info(data.error.message, "Information");
        return -1;
      } else {
        dispatch({ type: GET_DOCS, payload: data.content.data });
        // NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const createDocumentAction = (reqObj) => {
  return async (dispatch) => {
    try {
      const { data } = await _createDocument(reqObj);
      console.log("success created ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        dispatch(getDocuments());
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateDocumentAction = (obj) => {
  return async (dispatch) => {
    try {
      const { name, companyID, category, id, details } = obj;

      const reqObj = {
        data: {
          name,
          id,
          companyID,
          category,
          details,
        },
      };

      console.log("update doc now", reqObj);
      const { data } = await _updateDocument(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        dispatch(getDocuments());
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const deleteDocAction = (id) => {
  return async (dispatch) => {
    try {
      const reqObj = {
        data: {
          docID: id,
        },
      };

      const { data } = await _deleteDocument(reqObj);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        dispatch(getDocuments());
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const uploadFileToDocAction = (reqObj) => {
  return async (dispatch) => {
    try {
      const { data } = await _uploadFileToDocument(reqObj);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
