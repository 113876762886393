import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle, FiDivide } from "react-icons/fi";
import {
  OverlayTrigger,
  Spinner,
  Tooltip,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import { useDispatch } from "react-redux";
import { uploadFileToDocAction } from "../../store/actions/DocumentActions";

const UploadFileToDoc = ({ data, display, refID }) => {
  const [imageFile, setImageFile] = useState(null);
  const [otherFileType, setOtherFileType] = useState(null);
  const [base64Val, setBase64Val] = useState("");
  const [dynamicBase64Val, setDynamicBase64Val] = useState(null);
  const [imgDisplay, setImageDisplay] = useState("none");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  //
  const [fileData, setFileData] = useState(null);
  //
  const handleFile = (files) => {
    let result = [];

    [...files].map(async (item) => {
      console.log("this file item", item);

      // if (item.type.substr(0, 5) === "image") {
      let imgBase64 = await convFileToBase64(item);

      console.log("image image", imgBase64);
      result.push({
        base64: imgBase64,
        note: "description",
        fileName: item.name,
        docName: item.name,
        isImage: "1",
      });
      // }
    });

    console.log("file uploaded result", result);
    setFileData(result);
  };

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const handleSubmitDocument = async (values) => {
    console.log("files upload", values);
    setLoading(true);
    const { fileName } = values;
    const reqObj = {
      data: {
        companyID: values.companyID,
        refID: values.refID,
        files: fileData,
      },
    };
    console.log("files IIII upload", reqObj);
    const response = await dispatch(uploadFileToDocAction(reqObj));
    if (response) setLoading(false);
  };

  const convFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // setBase64Val(reader.result);
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  // const convFileToBase64 =  (file) => {
  //   let reader = new FileReader();
  //   let res;
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setBase64Val(reader.result);
  //     // return reader.result;
  //   };
  //   return base64Val;
  // };

  // const handleDynamicBase64 = (e, file) => {
  //   console.log("event", e.target);
  //   const name = e.target.name;
  //   // setDynamicBase64Val({ name: file})
  // };

  return (
    <>
      <Row>
        <Col>
          <Formik
            initialValues={{
              companyID: "history",
              refID: `${refID && refID}`,
              files: [
                {
                  fileName: "",
                  base64: `${base64Val}`,
                },
              ],
            }}
            validationSchema={Yup.object({
              files: Yup.array().of(
                Yup.object().shape({
                  fileName: Yup.string(),

                  base64: Yup.string(),
                })
              ),
            })}
            onSubmit={(values) => {
              console.log("form submit", values);
              handleSubmitDocument(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col lg="12" className="mt-3">
                    <label htmlFor="files">File Upload</label>
                    <FieldArray name="files">
                      {({ push, remove }) => (
                        <>
                          {values.files.map((item, index) => {
                            const noteName = `files[${index}].note`;
                            const isImageName = `files[${index}].isImage`;
                            const docNameName = `files[${index}].docName`;
                            const fileNameName = `files[${index}].fileName`;
                            const base64Name = `files[${index}].base64`;

                            const noteError = getIn(errors, noteName);
                            const isImageError = getIn(errors, isImageName);
                            const docNameError = getIn(errors, docNameName);
                            const base64Error = getIn(errors, base64Name);
                            const fileNameError = getIn(errors, fileNameName);

                            return (
                              <div key={index}>
                                <>
                                  <div
                                    className="my-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      paddingTop: 5,
                                    }}
                                  >
                                    {/* <label>Document {index + 1}</label> */}
                                    {/* <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"1rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger> */}
                                  </div>

                                  {/* <input
                                    id="files"
                                    name={docNameName}
                                    placeholder="Name of File"
                                    value={item.docName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="mb-2 form-control"
                                  />
                                  {docNameError && (
                                    <small className="text-danger">
                                      {docNameError}
                                    </small>
                                  )} */}

                                  {/* <select
                                    id="files"
                                    name={isImageName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={item.isImage}
                                    className="my-2 form-control"
                                  >
                                    <option value="">Select file type</option>
                                    <option value="1">Image</option>
                                    <option value="0">others</option>
                                  </select>
                                  {isImageError && (
                                    <small className="text-danger">
                                      {isImageError}
                                    </small>
                                  )} */}

                                  {/* <textarea
                                    id="files"
                                    name={noteName}
                                    placeholder="Description"
                                    value={item.note}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="my-2 form-control"
                                    rows="3"
                                  /> */}

                                  {/* {noteError && (
                                    <small className="text-danger">
                                      {noteError}
                                    </small>
                                  )} */}

                                  <Col className="custom-file">
                                    <input
                                      id="imgfile"
                                      type="file"
                                      className="custom-file-input"
                                      name={fileNameName}
                                      value={item.fileName}
                                      multiple
                                      onChange={(e) => {
                                        console.log(
                                          "on change files",
                                          e.target.files[0]
                                        );
                                        handleFile(e.target.files);

                                        // const file = e.target.files[0];
                                        // setFieldValue(
                                        //   `files[${index}][base64]`,
                                        //   file.name
                                        // );

                                        // if (
                                        //   file &&
                                        //   file.type.substr(0, 5) === "image"
                                        // ) {
                                        //   setFieldValue(`fileName`, file.name);
                                        //   setImageFile(file);
                                        //   handleDynamicBase64(e, file);
                                        // } else {
                                        //   setFieldValue("fileName", file.name);
                                        //   setOtherFileType(file);
                                        // }
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="imgfile"
                                    >
                                      Choose files to upload
                                    </label>
                                    {fileNameError && (
                                      <small className="text-danger">
                                        {fileNameError}
                                      </small>
                                    )}
                                  </Col>
                                  {/* <img
                                    src={base64Val}
                                    width={50}
                                    height={100}
                                    style={{ display: `${imgDisplay}` }}
                                  /> */}
                                </>
                              </div>
                            );
                          })}

                          {/* <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Add More</Tooltip>}
                          >
                            <FiPlusCircle
                              onClick={() => {
                                console.log("reached");
                                return push({
                                  note: "",
                                  isImage: "",
                                  fileName: "",
                                  docName: "",
                                  base64: "",
                                });
                              }}
                              className="my-2"
                              size={24}
                              style={{ cursor: "pointer" }}
                            />
                          </OverlayTrigger> */}
                        </>
                      )}
                    </FieldArray>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="text-right mt-2">
                    <Button
                      className="my-1"
                      theme="accent"
                      disabled={loading}
                      onClick={handleSubmit}
                      size="sm"
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />
                      )}
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default UploadFileToDoc;
