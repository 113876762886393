import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import DocumentReducer from "./DocumentReducer";

const reducers = combineReducers({
  Documents: DocumentReducer,
  Auth: AuthReducer,
});

export default reducers;
