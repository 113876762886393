import React, { useState } from "react";
import { Card } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import FileViewer from "react-file-viewer";
import { FilePreviewerThumbnail } from "react-file-previewer";
import FilePreviewer from "react-file-previewer";
import { Link } from "react-router-dom";
import { baseUrl } from "../api";

function ImageViewer({ item, docUrls }) {
  return (
    <>
      <Card className="card-container">
        <Card.Body>
          <a href={`${baseUrl}/${item.url}`} target="_blank">
            {item.docName}
          </a>
          <pre>{item.note}</pre>
        </Card.Body>
      </Card>
    </>
  );
}

export default ImageViewer;
