import React, { useEffect, useState } from "react";
import { Button, Card, Spinner, Row, Col } from "react-bootstrap";
import logo from "../../images/lasepa-logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import AddCategory from "./AddCategory";
import Document from "./Document";
import { getDocuments } from "../../store/actions/DocumentActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

function CreateDocument() {
  const [loading, setLoading] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const documents = useSelector((state) => state.Documents.documents);
  const dispatch = useDispatch();

  console.log("this documents", documents);

  const toggleAddCategory = () => {
    setOpenAddCategory(true);
  };

  const handleCloseModal = () => {
    setOpenAddCategory((val) => !val);
  };

  useEffect(() => {
    async function callDocuments() {
      setLoading(true);
      const response = await dispatch(getDocuments());
      if (response) {
        setLoading(false);
      }
    }

    callDocuments();
  }, []);

  return (
    <div>
      <AddCategory handleClose={handleCloseModal} open={openAddCategory} />
      <div
        className="w-100 px-5 d-flex align-items-center"
        style={{
          backgroundColor: "#eee",
        }}
      >
        <img
          alt="lasepa logo"
          onClick={() => {
            return (window.location.pathname = "/");
          }}
          src={logo}
          style={{ maxWidth: "250px" }}
        />
      </div>

      <div className="px-5">
        <div className=" text-right mt-4">
          <Row>
            <Col>
              <Button size="sm" onClick={toggleAddCategory}>
                {/* add new category */}
                Add New Document
              </Button>
            </Col>
          </Row>
        </div>
        <Row className="my-3">
          <Col>
            {!loading ? (
              documents.length > 0 ? (
                documents.map((item, index) => {
                  return (
                    <Card key={index} className="my-2 p-3">
                      <Document index={index} item={item} />
                    </Card>
                  );
                })
              ) : (
                <p>No documents available... Please create a new document</p>
              )
            ) : (
              <div className="w-100 text-center mt-4">
                <Spinner
                  className="text-center"
                  animation="border"
                  variant="secondary"
                  size="sm"
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateDocument;
