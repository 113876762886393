import React from "react";
import { Link } from "react-router-dom";
import { Col, Card, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getDocs } from "../store/actions/DocumentActions";

const DocumentList = ({ data }) => {
  const dispatch = useDispatch();

  const handleViewDoc = async (refID) => {
    console.log("ref id", refID);
    await dispatch(getDocs(refID));
  };

  return (
    <Row className="my-4">
      {data &&
        data.map((post, idx) => {
          const metadata = post.details.split(",");
          const othermeta = [];
          for (let i = 4; i < metadata.length; i++) {
            othermeta.push(metadata[i]);
          }
          console.log("break", metadata);
          console.log("break even", othermeta);
          return (
            <Col lg="4" md="6" sm="12" className="mb-4" key={idx}>
              <Card className="card-post h-100">
                <Card.Header>
                  <Card.Title>{post.name}</Card.Title>
                </Card.Header>

                <Card.Body>
                  <Card.Title>Details</Card.Title>

                  <Card.Text className="p-0 m-0" style={{ fontSize: ".9rem" }}>
                    <Row>
                      <Col lg="6">
                        <span style={{ color: "#80868B" }}>Category:</span>{" "}
                        &nbsp;{" "}
                      </Col>
                      <Col lg="6">{post.category}</Col>
                    </Row>
                  </Card.Text>
                  <Card.Text
                    className=" m-0"
                    style={{ paddingTop: "5px", fontSize: ".9rem" }}
                  >
                    <Row>
                      <Col>
                        <span style={{ color: "#80868B" }}>Title:</span>{" "}
                      </Col>
                      <Col>{metadata[0]}</Col>
                    </Row>
                  </Card.Text>
                  <Card.Text
                    className="m-0"
                    style={{ paddingTop: "5px", fontSize: ".9rem" }}
                  >
                    <Row>
                      <Col>
                        <span style={{ color: "#80868B" }}>Company Name:</span>{" "}
                      </Col>
                      <Col>{metadata[3]}</Col>
                    </Row>
                  </Card.Text>
                  <Card.Text
                    className="m-0"
                    style={{ paddingTop: "5px", fontSize: ".9rem" }}
                  >
                    <Row>
                      <Col>
                        <span style={{ color: "#80868B" }}>
                          Consultant Name:
                        </span>{" "}
                      </Col>
                      <Col>{metadata[1]}</Col>
                    </Row>
                  </Card.Text>
                  <Card.Text
                    className=" m-0"
                    style={{ paddingTop: "5px", fontSize: ".9rem" }}
                  >
                    <Row>
                      <Col>
                        <span style={{ color: "#958888" }}>Others:</span>
                      </Col>
                      <Col>{othermeta.join(", ")}</Col>
                    </Row>
                  </Card.Text>
                  <Card.Text
                    className=" m-0"
                    style={{ paddingTop: "5px", fontSize: ".9rem" }}
                  >
                    <Row>
                      <Col>
                        <span style={{ color: "#958888" }}>Date Added:</span>
                      </Col>
                      <Col>
                        {metadata[2].includes("-") ? metadata[2] : null}
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted border-top py-3">
                  {/* <h6
                      className="btn text-primary"
                      onClick={() => handleViewDoc(post.refID)}
                    >
                      View Details
                    </h6> */}
                  <span className="d-inline-block">
                    <Link
                      to={{
                        // pathname: `https://lspapp.touchandpay.me/${post.url}`,
                        pathname: `/view-document`,
                        state: post.refID,
                      }}
                      // target="_blank"
                    >
                      View Details
                    </Link>
                  </span>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
    </Row>
  );
};

export default DocumentList;
