const initialState = {
  authenticated: false,
};

export default function (state = initialState, actions) {
  console.log("reducer ", actions);
  switch (actions.type) {
    case "AUTH":
      return {
        ...state,
        authenticated: actions.payload,
      };
    default: {
      return state;
    }
  }
}
