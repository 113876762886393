import Home from "./views/Home";
import ViewDoc from "./views/Home/ViewDoc";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Signin from "./views/Authentication/Signin";
import ProtectedRoute from "./ProtectedRoute";
import CreateDocument from "./views/CreateDocument";

function App() {
  const token = sessionStorage.getItem("token");
  // console.log = function () {};
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/signin" component={Signin} />
        <ProtectedRoute component={Home} exact path="/" />
        <ProtectedRoute
          component={CreateDocument}
          exact
          path="/create-document"
        />
        <ProtectedRoute component={ViewDoc} exact path="/view-document" />
        {/* {token ? (
          <Route exact path="/" component={Home} />
        ) : (
          <Redirect to="/signin" />
        )} */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
