import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Searchbar from "../../components/Searchbar";
import DocumentList from "../../components/DocumentList";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../store/actions/DocumentActions";
import { Button, Spinner } from "react-bootstrap";
import logo from "../../images/lasepa-lag.png";

function Home() {
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.Documents.documents);

  // useEffect(() => {
  //   async function callDocuments() {
  //     await dispatch(getDocuments());
  //   }

  //   callDocuments();
  // }, []);

  return (
    <div
      style={{ backgroundColor: "#F6F5F2", minHeight: "100vh" }}
      className="d-flex flex-column justify-content-center p-2 "
    >
      <Navbar />
      <Searchbar />
      <div className="text-center">
        <Link
          to="/create-document"
          className="btn btn-primary"
          style={{ fontSize: ".8rem" }}
        >
          Add Document
        </Link>
      </div>

      {documents.length > 0 ? <DocumentList data={documents} /> : null}
    </div>
  );
}

export default Home;
