import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { searchDocuments } from "../store/actions/DocumentActions";
import { Spinner } from "react-bootstrap";

function Searchbar() {
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (searchVal.trim() == "") {
      NotificationManager.info("Search box is empty", "Information");
      return;
    }

    setLoading(true);
    const encodedParam = btoa(searchVal);
    const response = await dispatch(searchDocuments(encodedParam));
    if (response) {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
      <div className="input-group search-box mb-3  ">
        <input
          type="text"
          className="form-control p-3"
          placeholder="Search"
          aria-label="File name"
          aria-describedby="basic-addon2"
          value={searchVal}
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-primary"
            type="button"
            onClick={handleSubmit}
          >
            {loading && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Searchbar;
