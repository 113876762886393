import axios from "axios";

// export const baseUrl = "https://lspapp.touchandpay.me/";
export const baseUrl = "https://lasepa.xyz";

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(async (config) => {
  let token = sessionStorage.getItem("token");
  if (token) {
    config.headers = {
      Authorization: `bearer ${token}`,
      "Content-Type": "application/json",
    };
  }
  return config;
});

export default axiosInstance;
