import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDocs } from "../../store/actions/DocumentActions";
import { Row, Col } from "react-bootstrap";
import ImageViewer from "../../components/ImageViewer";
import Navbar from "../../components/Navbar";

function ViewDoc() {
  const [docUrls, setDocUrls] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const docs = useSelector((state) => state.Documents.docs);
  console.log("docss", docs);

  useEffect(() => {
    async function callGetDoc() {
      await dispatch(getDocs(state));
    }
    callGetDoc();
  }, []);

  useEffect(() => {
    if (docs) {
      const res = docs.map(
        (item) => "https://lspapp.touchandpay.me/" + item.url
      );

      setDocUrls(res);
    }
  }, [docs]);

  return (
    <>
      <Navbar bg={"#E1E6CC"} />
      <div className="text-center m-4 ">
        <Row>
          {docs &&
            docs.map((item, index) => {
              return (
                <Col lg="3" className="my-2">
                  <ImageViewer item={item} docUrls={docUrls} />
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default ViewDoc;
