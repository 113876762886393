import React from "react";
import logo from "../images/lasepa-lag.png";

function Navbar({ bg }) {
  return (
    <div className="w-100 pb-2 text-center " style={{ backgroundColor: bg }}>
      <div className="bg-image">
        <img src={logo} width={230} />
      </div>
      <h2>LASEPA Digital Directory</h2>
      <p className="font-italic font-weight-light header-child">
        ...a directory of all LASEPA documents.
      </p>
    </div>
  );
}

export default Navbar;
